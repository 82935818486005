/** @jsx jsx */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Styled, jsx } from 'theme-ui'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'
import { Label, Input, Select, Textarea, Radio, Checkbox, Slider, Button, Box } from 'theme-ui'

import { Hero, Section, Icon } from '../components'

const SocialButton = styled.a``

class ContactIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />
          <Hero
            title='Get in touch'
            subtitle='If you have any questions don’t hesitate to contact us.'
            size={[4, 6]}
            minHeight={[0, '40vh']}
          />
          <Section variant='white'>
            <div
              sx={{
                display: ['flex', 'grid'],
                flexDirection: 'column',
                gridTemplateColumns: [
                  'repeat(auto-fill, minmax(`100%`, 1fr))',
                  'repeat(auto-fill, minmax(500px, 1fr))',
                ],
                gridAutoRows: '1fr',
                gridGap: [0, 0, 0, 6],
                alignItems: 'stretch',
              }}
            >
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  mb: [4, 0],
                }}
              >
                <Styled.h2 sx={{ m: 0, p: 0 }}>Email Us</Styled.h2>
                <Styled.p sx={{ m: 0, p: 0 }}>
                  Fill out the form with your enquiry and we'll do our best to respond within 48
                  hours.
                </Styled.p>
              </div>
              <div sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                  as='form'
                  method='post'
                  netlify-honeypot='bot-field'
                  data-netlify='true'
                  name='contact'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <input type='hidden' name='bot-field' />
                  <input type='hidden' name='form-name' value='contact' />
                  <label>
                    <Label htmlFor='name'>Name</Label>
                    <Input type='text' name='name' id='name' mb={3} />
                  </label>
                  <label>
                    <Label htmlFor='email'>E-mail Address</Label>
                    <Input type='email' name='email' id='email' mb={3} />
                  </label>
                  <label>
                    <Label htmlFor='Subject'>Subject</Label>
                    <Select name='Subject' id='Subject' mb={3}>
                      <option>Enquiry</option>
                      <option>General</option>
                      <option>Other</option>
                    </Select>
                  </label>
                  <label>
                    <Label htmlFor='message'>Message</Label>
                    <Textarea name='message' id='message' rows='5' mb={3} />
                  </label>
                  <Button type='submit'>Send</Button>
                </Box>
              </div>
            </div>
          </Section>
          <Section variant='transparent'>
            <div
              sx={{
                display: 'grid',
                gridTemplateColumns: [
                  'repeat(auto-fill, minmax(250px, 1fr))',
                  'repeat(auto-fill, minmax(400px, 1fr))',
                ],
                gridAutoRows: '1fr',
                gridGap: 5,
                alignItems: 'stretch',
              }}
            >
              <div>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2424.009643644524!2d-1.8895878841900284!3d52.58751887982705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870a409e746b379%3A0x1082ea85abdd8676!2s1DP%2C%20128%20Hardwick%20Rd%2C%20Streetly%2C%20Sutton%20Coldfield%20B74%203DP!5e0!3m2!1sen!2suk!4v1594418360122!5m2!1sen!2suk'
                  width='100%'
                  height='350px'
                  frameborder='0'
                  allowfullscreen=''
                  aria-hidden='false'
                  tabindex='0'
                  title='Google Map Embed'
                ></iframe>
              </div>
              <div>
                <div>
                  <h1>In the area?</h1>
                  <address>
                    128-138 Hardwick Road <br />
                    Sutton Coldfield <br />
                    B74 3DP
                  </address>
                </div>
                <div>
                  <h1>Store Hours</h1>
                  Monday - Saturday 9am - 5pm <br />
                  Sunday Closed
                </div>
              </div>
              <div>
                <div sx={{ display: 'flex', flexDirection: 'column' }}>
                  <h1>Contact</h1>

                  <Styled.a
                    href='mailto:info@smarthomeandcinema.co.uk'
                    sx={{ display: 'flex', alignItems: 'center', mb: 2, textDecoration: 'none' }}
                  >
                    <Icon name='email' color='primary' size='32px' mr={2} />
                    info@smarthomeandcinema.co.uk
                  </Styled.a>
                  <Styled.a href='tel:01217251875' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Icon name='phone' color='primary' size='32px' mr={2} />
                    01217251875
                  </Styled.a>
                </div>
                <div>
                  <h1>Social</h1>
                  <div sx={{ diplay: 'flex', flexDirection: 'row' }}>
                    <SocialButton href='https://www.twitter.com/smarthomecinema' sx={{ mr: 2 }}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='44'
                        height='44'
                        fill='none'
                        viewBox='0 0 32 32'
                      >
                        <path
                          sx={{
                            fill: `primary`,
                          }}
                          d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm6.508 13.107c.007.136.009.273.009.406 0 4.167-3.169 8.969-8.965 8.969a8.892 8.892 0 01-4.83-1.417c.245.03.496.042.751.042a6.312 6.312 0 003.914-1.349 3.159 3.159 0 01-2.944-2.186c.472.09.958.07 1.422-.055a3.153 3.153 0 01-2.528-3.09v-.039a3.16 3.16 0 001.428.395 3.15 3.15 0 01-.975-4.21 8.96 8.96 0 006.495 3.295 3.152 3.152 0 015.37-2.875 6.326 6.326 0 002-.765 3.166 3.166 0 01-1.385 1.745 6.331 6.331 0 001.81-.498 6.39 6.39 0 01-1.572 1.632z'
                        ></path>
                      </svg>
                    </SocialButton>
                    <SocialButton
                      href='https://www.instagram.com/smarthomeandcinema'
                      sx={{ mr: 2 }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='44'
                        height='44'
                        fill='none'
                        viewBox='0 0 32 32'
                      >
                        <path
                          sx={{
                            fill: `primary`,
                          }}
                          d='M21 16a5 5 0 11-10 0c0-.285.03-.563.082-.833H9.333v6.661c0 .464.375.839.839.839H21.83a.838.838 0 00.837-.839v-6.661h-1.749c.052.27.082.548.082.833zm-5 3.333a3.334 3.334 0 10-.001-6.667A3.334 3.334 0 0016 19.333zm4-6.833h1.998a.502.502 0 00.502-.5v-1.998a.502.502 0 00-.502-.502H20a.501.501 0 00-.502.502V12c.002.275.227.5.502.5zM16 0a16 16 0 100 32 16 16 0 000-32zm8.333 22.482a1.857 1.857 0 01-1.851 1.851H9.518a1.857 1.857 0 01-1.851-1.851V9.518c0-1.018.833-1.851 1.851-1.851h12.964c1.018 0 1.851.833 1.851 1.851v12.964z'
                        ></path>
                      </svg>
                    </SocialButton>
                    <SocialButton href='#'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='44'
                        height='44'
                        fill='none'
                        viewBox='0 0 32 32'
                      >
                        <path
                          sx={{
                            fill: `primary`,
                          }}
                          d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm3.79 11.057h-2.405c-.285 0-.602.375-.602.873v1.737h3.009l-.455 2.476h-2.554v7.435h-2.838v-7.435H11.37v-2.476h2.575V12.21c0-2.09 1.45-3.788 3.44-3.788h2.405v2.635z'
                        ></path>
                      </svg>
                    </SocialButton>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </Layout>
    )
  }
}

export default ContactIndex

export const pageQuery = graphql`
  query ContactIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
